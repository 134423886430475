import { buildInValidationMessages } from "./builtInValidationMessages"

//Variable that indicates that the form has to focus the first field with error after the submit
let focusFieldError = true

/**
 * Find form by className and enabled the submit button after javascript is loaded
 * @param classForm class name of the form
 * @returns {Element}
 */
const initForm = (classForm) => {
    const form = document.querySelector(`.${classForm}`)

    //disabled the submit button until this js file is loaded
    const submitBtn = form.querySelector("button[type='submit']")
    submitBtn.disabled = false

    return form
}

/**
 * Submit and validate the form
 * @param form that it is being submitted and validated
 * @param formElements elements that it forms part of the form
 */
const submitAndValidationForm = (form, formElements) => {
    form.addEventListener(
        "submit",
        (event) => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }
            focusFieldError = true
            formElements.forEach((formInput) => {
                if (formInput.validate) formInput.validate()
            })
            form.classList.add("was-validated")
        },
        false
    )

    /**
     *
     * @param {{element: HTMLInputElement, validation?: ((element: HTMLInputElement) => string)[]}} formInput
     */
    const setupInputElementForValidation = (formInput) => {
        /**
         * @type {Element}
         */
        const feedbackElement =
            formInput.element.parentElement.getElementsByClassName(
                "wm-form-message"
            )[0]

        const formLabelElement =
            formInput.element.parentElement.getElementsByClassName(
                "wm-form-label"
            )[0]

        const elementInvalid = () => {
            formLabelElement.classList.add("wm-form-label--error")
            formInput.element.setAttribute("aria-invalid", "true")
        }

        const elementValid = () => {
            formLabelElement.classList.remove("wm-form-label--error")
            formInput.element.setAttribute("aria-invalid", "false")
        }

        const validate = () => {
            formInput.element.setCustomValidity("")
            feedbackElement.innerHTML = "&nbsp;"
            elementValid()
            if (formInput.validation) {
                formInput.validation.forEach((func) => {
                    const validationValue = func(formInput.element)
                    if (!!validationValue) {
                        formInput.element.setCustomValidity("invalid")
                        feedbackElement.innerHTML = validationValue
                    }
                })
            }
            buildInValidationMessages.forEach((message) => {
                if (formInput.element.validity[message.type]) {
                    if (message.type === "tooShort") {
                        feedbackElement.innerHTML = message.message(
                            formInput.element.minLength
                        )
                    } else {
                        feedbackElement.innerHTML = message.message()
                    }
                    if (focusFieldError) {
                        formInput.element.focus()
                        focusFieldError = false
                    }
                    elementInvalid()
                }
            })
        }

        formInput.validate = validate

        formInput.element.onblur = () => {
            formInput.element.parentElement.classList.add("was-validated")
            validate()
        }

        formInput.element.oninput = () => {
            if (
                formInput.element.parentElement.classList.contains(
                    "was-validated"
                ) ||
                formInput.element.type === "checkbox"
            ) {
                validate()
            }
        }

        formInput.element.onsubmit = () => {
            validate()
        }
    }

    formElements.forEach(setupInputElementForValidation)
}

export { initForm, submitAndValidationForm }
