import { initForm, submitAndValidationForm } from "./generalForm.js"

;("use strict")
;(() => {
    const className = "validation-subscription-newsletter-form"

    const form = initForm(className)

    const formElements = [
        {
            element: form.querySelector("#newsletter_email")
        },
        {
            element: form.querySelector("#newsletter_consentDataNewsletter")
        }
    ]

    submitAndValidationForm(form, formElements)
})()
