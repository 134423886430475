/*
    Javascript file that define different messages for the validations of the form
 */

export const buildInValidationMessages = [
    {
        type: "valueMissing",
        message: () => "Pflichtfeld"
    },
    {
        type: "typeMismatch",
        message: () => "E-Mail ist nicht gültig"
    },
    {
        type: "patternMismatch",
        message: () =>
            "Passwort entspricht nicht den Anforderungen, mind. 1 Kleinbuchstabe, mind. 1 Großbuchstabe, mind. 1 Nummer"
    },
    {
        type: "tooShort",
        message: (size) => `Muss mindestens ${size} Zeichen lang sein`
    }
]
